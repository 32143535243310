import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataLst, fetchScrollInfoRefresh ,changeInsectCount } from "redux/slices/dataSlice";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

export default function InsectCountModal({ open, title, insect, setOpen, page, pageNum }) {

    const dispatch = useDispatch();
    const { trapId, dataLst } = useSelector((state) => state.data);
    const { filter, ascend, currTeam } = useSelector((state) => state.auth);

    // Holds states
    const [insectCount, setInsectCount] = useState("");
    const [invalidInsectCount, setInvalidInsectCount] = useState(false);
    const [insectCountError, setInsectCountError] = useState("");
    const [fakeSubmitting, setFakeSubmitting] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const submitInsectCountChange =  async () => {
        if(insectCount && !invalidInsectCount) {
          setFakeSubmitting(false);
          
          let body = {
            fileName: dataLst[page - 1].fileName,
            trapId: trapId,
            newCount: insectCount,
            insect: insect,
          };
      
          await dispatch(changeInsectCount(body));
          setOpen(false);
          setInsectCount("");
          await dispatch(fetchDataLst(trapId));

          let payload = {
            msg: {
              filter: filter,
              ascend: ascend,
              teamName: currTeam,
            },
            num: pageNum,
          };
          
          await dispatch(fetchScrollInfoRefresh(payload));
          
        } else {
          setFakeSubmitting(true);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
        setInvalidInsectCount(false);
        setInsectCount();
        setFakeSubmitting(false);
    };

    useEffect(() => {
        if(insectCount) {
            if(isNaN(insectCount)) {
                setInvalidInsectCount(true);
                setInsectCountError("Only numbers");
                setButtonDisabled(true)
            }
            else if(insectCount >= 100) {
                setInvalidInsectCount(true);
                setInsectCountError("Can't be greater than 99");
                setButtonDisabled(true);
            } else {
                setInvalidInsectCount(false);
                setButtonDisabled(false);
            }
        } else {
            setInvalidInsectCount(false);
            setButtonDisabled(false);
        }
    }, [insectCount]);
    
    const handleInsectCountChange = (event) => {
        setInsectCount(event.target.value);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleCloseModal} fullWidth={false} maxWidth={'xs'}>
                <DialogTitle>Edit {title}</DialogTitle>
                <DialogContent>
                <Box
                    component="form"
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                    }}
                >
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <TextField
                            id="outlined-number"
                            label="Insect Count"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleInsectCountChange}
                        />
                        {!insectCount && fakeSubmitting && <FormHelperText style={{ fontWeight: "bold", color: "red" }}>Can't be blank</FormHelperText>}
                        {invalidInsectCount && <FormHelperText style={{ fontWeight: "bold", color: "red" }}>{insectCountError}</FormHelperText>}
                    </FormControl>
                </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="contained" onClick={submitInsectCountChange} disabled={buttonDisabled}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

 