/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./info-box.sass";

import { useSelector, useDispatch } from "react-redux";
import { fetchDataLst, fetchScrollInfo, fetchTrapInfo, updateTrap } from "redux/slices/dataSlice";

import InfoBar from "components/info-bar/info-bar-2/InfoBar2";
import ParticleCountInfoBar from "components/info-bar/info-bar-2/ParticleCountInfoBar";
import InsectCounterInfoBar from "components/info-bar/info-bar-2/InsectCounterInfoBar";
import PageBar from "components/info-bar/page-bar/PageBar";
import TrapImgModal from "components/modal/TrapImgModal";

import WifiIndicator from "components/iconmodal/WifiIndicator";
import BatteryIndicator from "components/iconmodal/BatteryIndicator";
import InsectIndicator from "components/iconmodal/InsectIndicator";
import settingsBtn from "assets/cog-solid.png";
import saveBtn from "assets/save_icon.png";
import FavoriteButton from "components/btn/FavoriteButton";

import ModalForm from "components/modal/ModalForm";
import TrapFunctions from "components/modal/TrapFunctions";

//<div className= {moreInfo ? "pic-section-content-active": "pic-section-content-nonactive" }>
export default function InfoBox({ pop, setPop, pageNum }) {

  const dispatch = useDispatch();
  const { trapId, trapLst, dataLst, location, building, trapName, brightness, frequency, loading, scrollInfo } = useSelector((state) => state.data);
  const [page, setPage] = useState(1);
  const [moreInfo, setMoreInfo] = useState(false);

  const [open, setOpen] = useState(false); // Controls when the modal form opens
  const [openFuncs, setOpenFuncs] = useState(false); //Controls when function form opens

  useEffect(() => {
    if (trapId > 0) {
      setPop(false);
      setPage(1);
      dispatch(fetchDataLst(trapId));
    }
  }, [trapId]);


  // updates the info box if any detector's info is changed
  useEffect(() => {
    if (trapLst) {
      dispatch(fetchTrapInfo(trapId));
    }
  }, [trapName, location, building, frequency, brightness]);


  const toggleMoreInfo = () => {
  
    if(moreInfo){
      setMoreInfo(false);
    } else {
      setMoreInfo(true);
    }
    
  };
  let dateDI = "";
  if (dataLst && dataLst.length > 0 && dataLst[page - 1].createdAt) {
    let createdAt = dataLst[page - 1].createdAt;
    let utcdate = new Date(createdAt);
    dateDI = utcdate.toLocaleString();
  }
  
  let freq = '';
    if (frequency !== -1){
        if (frequency === 1){
            freq = "5 minutes";
        } 
        else if(frequency === 2) {
            freq = "8 hours";
        } 
        else if(frequency === 3) {
            freq = "24 hours";
        } 
        else if(frequency === 4) {
            freq = "48 hours";
        } 
        else if(frequency === 5) {
            freq = "72 hours";
        } else if(frequency === 6) {
          freq = "4 hours";
      }
    }

    let bright = '';
    if (brightness !== -1){
        if (brightness === 1){
            bright = "lvl 1";
        } 
        else if(brightness === 2) {
          bright = "lvl 2";
        } 
        else if(brightness === 3) {
          bright = "lvl 3";
        } 
        else if(brightness === 4) {
          bright = "lvl 4";
        } 
        else if(brightness === 5) {
          bright = "lvl 5";
        }
        else if(brightness === 6) {
          bright = "lvl 6";
        }
        else if(brightness === 7) {
          bright = "lvl 7";
        }
        else if(brightness === 8) {
          bright = "lvl 8";
        }
        else if(brightness === 9) {
          bright = "lvl 9";
        }
    }

  const [btnDisable, setBtnDisable] = useState(false);
  const [navBtnDisable, setNavBtnDisable] = useState(false);
  useEffect(() => {
    if (loading) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }

    if (dataLst === null) {
      setNavBtnDisable(true);
    } else {
      setNavBtnDisable(false);
    }
  
  }, [loading, dataLst]);

  return (
    <div className="info-box">
      <div className="top-container">
        {pop && <TrapImgModal page={page} setPop={setPop} pop={pop} setPage={setPage} totalPage={dataLst ? dataLst.length : 0}/> }
        <div className="pic-section">
        {dataLst && dataLst[page - 1] ?
          <input
            type="image"
            className="pic"
            src={dataLst[page - 1]["originalFileUrl"]}
            alt="not available"
            onClick={() => setPop(true)}
            disabled={btnDisable}
          /> : <input
            type="image"
            className="pic-logo"
            src="https://particledetection-aivs-processed.s3.us-west-1.amazonaws.com/original/aivflogo2023.png"
            alt="not available"
            onClick={() => setPop(true)}
            disabled={btnDisable}
          /> 
        }
        <div className="pic-hover-content"></div>
      </div>
        <div className="right-info">
          <div className="info-bar1"> 
            <div className="info-bar-title1">SmartProbe Name:</div>
            <div className="info-bar-content1">{trapName ? trapName : "None"} </div>
          </div>
          <div className="info-bar1"> 
            <div className="info-bar-title1">Location:</div>
            <div className="info-bar-content1">{location ? location : "None"} </div>
          </div>
          <div className="info-bar1"> 
            <div className="info-bar-title1">Building:</div>
            <div className="info-bar-content1">{building ? building : "None"}</div>
          </div>
          <div className="edit">
            <div className="img">
              <input
                type="image" 
                alt="settings"
                src={settingsBtn}
                className={btnDisable ? "settingsDisabled" : "settings"}
                onClick={() => setOpen(true)}
                disabled={btnDisable}
              />
            </div>
            {open && <ModalForm open={open} setOpen={setOpen} pageNum={pageNum}/>}
            <div className="img">
              <input
                type="image" 
                alt="trapFunctions"
                src={saveBtn}
                className={btnDisable ? "settingsDisabled" : "settings"}
                onClick={() => setOpenFuncs(true)}
                disabled={btnDisable}
              />
            {openFuncs && <TrapFunctions openFuncs={openFuncs} setOpenFuncs={setOpenFuncs} pageNum = {pageNum}/>}
            </div>
          </div>

          <div className="icon-container">
          {dataLst && dataLst !== null ? (          
            <WifiIndicator iconvalue={dataLst[page - 1]["wifiInfo"]} ></WifiIndicator>        
            ) : <WifiIndicator iconvalue={0} ></WifiIndicator> 
          }          
          {dataLst && dataLst !==  null ? (          
            <BatteryIndicator count={dataLst[page - 1]["batteryInfo"]} ></BatteryIndicator>         
            ) : <BatteryIndicator count={0} ></BatteryIndicator> 
          }
          {dataLst && dataLst !== null ? (         
            <InsectIndicator count={dataLst[page - 1]["particleCount"]}></InsectIndicator>
            ) : <InsectIndicator count={-1}></InsectIndicator> 
          }
          { scrollInfo && scrollInfo.traps && scrollInfo.traps.find((element) => element.trapId === trapId.toString()) ? (
            <FavoriteButton
            stateBtn={
              scrollInfo.traps.find(
                (element) => element.trapId === trapId.toString()
              ).favorite
            }
            btnDisable={btnDisable}
            pageNum={pageNum}
          />
          ) : (
            <div></div>
          )}
          </div>
          <div className="info-bar1-page-bar">
            <PageBar
              content={dataLst ? page + "/" + dataLst.length : "0/0"}
              totalPage={dataLst ? dataLst.length : 0}
              page={page}
              setPage={setPage}
              handleDisable={navBtnDisable || loading}
            />
          </div>
          
        </div>
        
      </div>
        {moreInfo ? 
          <div></div> 
          : <div button onClick={toggleMoreInfo}>    
          <div className="showmore">
            <i title="More Info" className="arrow down"></i>
          </div>            
        </div> 
        }
      <div className = "collumns">
        <div className = "collumn1">
          <div className= {moreInfo ? "pic-section-content-active": "pic-section-content-nonactive" }>
            {dataLst && dataLst.length > 0 ? (          
              <InfoBar title={"trapId"} key={"trapId"} value={dataLst[page - 1]["trapId"]} />
            ) : (
              <InfoBar title={"trapId"} key={"404"} value={trapId} />
            )}
            <InfoBar title={"Frequency"} value={freq}/>
            <InfoBar title={"Brightness"} value={bright}/>
            {dataLst && dataLst.length > 0 ? (          
              <InfoBar title="Date" key={"createdAt"} value={dateDI} />
            ) : (
              <InfoBar title="Date" key={"405"} value={null} />
            )}
            {dataLst && dataLst.length > 0 ? (          
              <InfoBar title="Time" key={"createdAt"} value={dateDI} />
            ) : (
              <InfoBar title="Time" key={"409"} value={null} />
            )}
          </div>
        </div>
        <div className = "collumn2">
          <div className= {moreInfo ? "pic-section-content-active": "pic-section-content-nonactive" }>
          {dataLst && dataLst.length > 0 ? (          
            <ParticleCountInfoBar title = "Total Count" key={"particleCount"} value={dataLst[page - 1]["particleCount"]} num={page} pageNum={pageNum} />
          ) : (
            <ParticleCountInfoBar title = "Total Count" key={"particleCount"} value={null} num={page} pageNum={pageNum} />
          )}
          {dataLst && dataLst.length > 0 ? (          
            <InsectCounterInfoBar title = "Moth Count" insect={"moth"} value={dataLst[page - 1].insectCollection.moth} num={page} pageNum={pageNum} />
          ) : (
            <InsectCounterInfoBar title = "Moth Count" insect={"other"} value={null} num={page} pageNum={pageNum} />
          )}
          {dataLst && dataLst.length > 0 ? (          
            <InsectCounterInfoBar title = "Beetle Count" insect={"beetle"} value={dataLst[page - 1].insectCollection.beetle} num={page} pageNum={pageNum} />
          ) : (
            <InsectCounterInfoBar title = "Beetle Count" insect={"other"} value={null} num={page} pageNum={pageNum} />
          )}
          {dataLst && dataLst.length > 0 ? (          
            <InsectCounterInfoBar title = "Other Count" insect={"other"} value={dataLst[page - 1].insectCollection.other} num={page} pageNum={pageNum} />
          ) : (
            <InsectCounterInfoBar title = "Other Count" insect={"other"} value={null} num={page} pageNum={pageNum} />
          )}
          {dataLst && dataLst.length > 0 ? (          
                  <InfoBar title={"temperature"} key={"temperature"} value={dataLst[page - 1]["temperature"]} />
          ) : (
            <InfoBar title={"temperature"} key={"407"} value={null} />
          )}
          {dataLst && dataLst.length > 0 ? (          
            <InfoBar title={"humidity"} key={"humidity"} value={dataLst[page - 1]["humidity"]} />
          ) : (
            <InfoBar title={"humidity"} key={"408"} value={null} />
          )}
            </div>
          </div>
        </div>
        {moreInfo ? 
          <div button onClick={toggleMoreInfo}>    
            <div className="showmore">
              <i title="Less Info" className="arrow up"></i>
            </div>            
          </div> 
          : <div></div> }
      </div>
    
  );
}
